<template>
    <protect-register :typePage="typePage"></protect-register>
</template>

<script>
import ProtectRegister from "@/views/modules/collection/business/protect/protectRegister.vue";

export default {
    name: "protectRecord",
    components: {ProtectRegister},
    data(){
        return{
            typePage:'protectRecord',
        }
    },
}
</script>

<style scoped>

</style>